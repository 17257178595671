<template>
  <div>
    <div class="mt-5">
      <v-tabs v-model="tab">
        <v-tab>Data Utama</v-tab>
        <v-tab>Data Riwayat</v-tab>
        <v-tab>Data Kompetensi</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- TAB DATA UTAMA -->
        <v-tab-item>
          <v-card
            flat
            class="py-5"
            max-height="260"
            min-height="260"
            style="overflow-y: auto; overflow-x: hidden"
          >
            <v-row align="center" justify="center">
              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/datapribadi/' + nip"
              >
                <v-icon x-large color="primary">mdi-database-lock</v-icon>
                <h4 class="mt-1">Data Pribadi</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/pasangan/' + nip"
              >
                <v-icon x-large color="primary"
                  >mdi-human-male-female-child</v-icon
                >
                <h4 class="mt-1">Data Pasangan</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/anak/' + nip"
              >
                <v-icon x-large color="primary"
                  >mdi-account-child-circle</v-icon
                >
                <h4 class="mt-1">Data Anak</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/orangtua/' + nip"
              >
                <v-icon x-large color="primary">mdi-human-male-female</v-icon>
                <h4 class="mt-1">Data Orangtua & Mertua</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/saudara/' + nip"
              >
                <v-icon x-large color="primary"
                  >mdi-human-capacity-decrease</v-icon
                >
                <h4 class="mt-1">Data Saudara</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/databadan/' + nip"
              >
                <v-icon x-large color="primary">mdi-human-male-height</v-icon>
                <h4 class="mt-1">Keterangan Badan & Tubuh</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/dokpendukung/' + nip"
              >
                <v-icon x-large color="primary">mdi-database-plus</v-icon>
                <h4 class="mt-1">Data Pendukung</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/doktambahan/' + nip"
              >
                <v-icon x-large color="primary"
                  >mdi-database-refresh-outline</v-icon
                >
                <h4 class="mt-1">Data Lainnya</h4>
              </v-card>
            </v-row>
          </v-card>
        </v-tab-item>

        <!-- TAB DATA RIWAYAT -->
        <v-tab-item>
          <v-card
            flat
            class="py-5"
            max-height="260"
            min-height="260"
            style="overflow-y: auto; overflow-x: hidden"
          >
            <v-row align="center" justify="center">
              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/pengangkatan/' + nip"
              >
                <v-icon x-large color="primary">mdi-account-arrow-up</v-icon>
                <h4 class="mt-1">Pengangkatan</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/perker/' + nip"
              >
                <v-icon x-large color="primary">mdi-card-account-details</v-icon>
                <h4 class="mt-1">Perjanjian Kerja</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/pendidikan/' + nip"
              >
                <v-icon x-large color="primary">mdi-school</v-icon>
                <h4 class="mt-1">Pendidikan</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/gaji/' + nip"
              >
                <v-icon x-large color="primary"> mdi-finance </v-icon>
                <h4 class="mt-1">Gaji Berkala</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/cuti/' + nip"
              >
                <v-icon x-large color="primary"> mdi-beach </v-icon>
                <h4 class="mt-1">Cuti</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/penghargaan/' + nip"
              >
                <v-icon x-large color="primary">
                  mdi-card-account-details-star-outline
                </v-icon>
                <h4 class="mt-1">Penghargaan</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/organisasi/' + nip"
              >
                <v-icon x-large color="primary"> mdi-sitemap </v-icon>
                <h4 class="mt-1">Organisasi</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/hukdis/' + nip"
              >
                <v-icon x-large color="primary"> mdi-scale-balance </v-icon>
                <h4 class="mt-1">Hukdis</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/luarnegeri/' + nip"
              >
                <v-icon x-large color="primary"> mdi-airplane </v-icon>
                <h4 class="mt-1">Pengalaman Luar Negeri</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/bahasa/' + nip"
              >
                <v-icon x-large color="primary"> mdi-translate-variant </v-icon>
                <h4 class="mt-1">Bahasa yang dikuasai</h4>
              </v-card>
            </v-row>
          </v-card>
        </v-tab-item>

        <!-- TAB DATA KOMPETENSI -->
        <v-tab-item>
          <v-card
            flat
            class="py-5"
            max-height="260"
            min-height="260"
            style="overflow-y: auto; overflow-x: hidden"
          >
            <v-row align="center" justify="center">
              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/diklat/' + nip"
              >
                <v-icon x-large color="primary">mdi-book</v-icon>
                <h4 class="mt-1">Diklat</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/kursus/' + nip"
              >
                <v-icon x-large color="primary">mdi-cast-education</v-icon>
                <h4>Kursus</h4>
              </v-card>

              <v-card
                flat
                class="text-center primary--text pa-2 ma-3"
                :width="wCard"
                :to="'/pppk/kinerja/' + nip"
              >
                <v-icon x-large color="primary"> mdi-chart-line </v-icon>
                <h4 class="mt-1">Kinerja</h4>
              </v-card>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider class="my-7"></v-divider>

      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    wCard: 200,
    nip: "",
  }),

  mounted() {
    this.nip = this.$route.params.id;
  },

  methods: {},
};
</script>
